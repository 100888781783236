<template>
  <v-app>
    <v-container fluid>
        <v-row class="d-flex justify-center">
            <v-col
                class="pa-0"
                cols="12"
                md="8"
            >
                <v-card>
                    <v-card-text class="pa-0">  
                        <table>
                            <thead>
                                <tr>
                                    <th>服务器名</th>
                                    <th>服务器IP</th>
                                    <th style="min-width:160px">开区时间</th>
                                    <th>线路类型</th>
                                    <th>版本介绍</th>
                                    <th>客服QQ</th>
                                    <th style="min-width:80px">主页地址</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(server, index) in servers" :key="index" @click="goToLink(server.link)">
                                    <td>{{ server.title }}</td>
                                    <td>{{ server.ip }}</td>
                                    <td>{{ server.time }}</td>
                                    <td>{{ server.description }}</td>
                                    <td>{{ server.details }}</td>
                                    <td>{{ server.qq }}</td>
                                    <td><a :href="server.link" :target=server.link>点击查看</a></td>
                                </tr>
                            </tbody>
                        </table>                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
            

    </v-container>


    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',

  data(){
    return {
        servers: [],
        error: null,
    }
  },
  created() {
    this.loadServers();
  },
  methods: {
    goToLink(url) {
      window.location.href = url; // 跳转到指定的链接
    },
    async loadServers() {
      try {
        const response = await axios.get('/serverslist.json'); // JSON 文件路径
        this.servers = response.data;
      } catch (err) {
        this.error = '加载服务器列表失败';
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>

    table {
        border-collapse: collapse; /* 合并边框 */
        width: 100%; /* 可选：设置宽度 */
    }
    th {
        background-color: #A61F0D; /* 您想要的背景色 */
        color: #F1E68E;
        border: 1px solid black; /* 设置边框 */
        padding: 8px; /* 设置内边距 */
        text-align: center; /* 中心居中 */    
    }
    td {
        border: 1px solid black; /* 设置边框 */
        padding: 4px; /* 设置内边距 */
        text-align: center; /* 中心居中 */    
    }
    /* 默认的 tr 背景色 */
    tr {
        background-color: #FFF8DC; /* 默认背景色 */
    }

    /* 鼠标悬停时变成某种颜色 */
    tr:hover {
        background-color: #6B9B0C; /* 鼠标悬停时的背景色 */
    }
    .highlight {
        background-color: #ffff00; /* 特定背景颜色 */
        color: black; /* 字体颜色 */
    }

</style>